@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.personal-wrap {
  width: 100%;
  height: 898px;
  padding: 20px;
  background-color: #eef1f0;
  .personal-content-wrap {
    width: 1200px;
    //width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
    .personal-menu-wrap {
      width: 20%;
      height: 100%;
      background-color: white;
      .person-menu-item {
        width: 100%;
        display: flex;
        align-items: center;
        color: #333333;
        height: 56px;
        padding-left: 25px;
        font-size: 14px;
        cursor: pointer;
      }
      .personal-menu-item_check {
        width: 100%;
        display: flex;
        align-items: center;
        color: #14bd85;
        height: 56px;
        padding-left: 25px;
        font-size: 14px;
        font-weight: bold;
        background-color: #cef3e7;
        position: relative;
        cursor: pointer;
        &::before {
          content: '';
          height: 100%;
          width: 4px;
          background-color: #14bd85;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .personal-router-wrap {
      height: 100%;
      margin-left: 1%;
      width: 79%;
    }
  }
}

@primary-color: #14BD85;