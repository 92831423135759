@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.login-wrap:extend(.flex-column-center) {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 200px;
  .login-title {
    font-size: 32px;
    color: #2f312e;
    font-weight: bold;
    position: relative;
    margin-bottom: 38px;
    &::before {
      content: '';
      display: block;
      width: 150px;
      height: 1px;
      background-color: #d9d9d9;
      position: absolute;
      top: 50%;
      left: -173px;
    }
    &::after {
      content: '';
      display: block;
      width: 150px;
      height: 1px;
      background-color: #d9d9d9;
      position: absolute;
      top: 50%;
      right: -173px;
    }
  }
  .vrcode-wrap {
    display: flex;
    width: 410px;
    justify-content: flex-start;
  }
}

@primary-color: #14BD85;