@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.my-product-data-wrap{
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  .product-data-content_wrap {
    padding: 22px;
    flex: 1;
    overflow-y: auto;
    .my-product-item{
      margin-bottom: 20px;
      background: #ebfff9;
      .product-data-item {
        color: #666666;
        font-size: 14px;
        &:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}

@primary-color: #14BD85;