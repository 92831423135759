@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.header-wrap {
  width: 100%;
  height: 50px;
  margin: auto;
  z-index: 999;

  //padding: 0px 18.75% 0 18.75%;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, .9);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  .header-content-wrap {
    max-width: 1200px;
    margin: auto;

    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .header-login {
      width: 70px;
      height: 36px;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 1px solid #dddfe5;
      font-size: 14px;
      color: #333333;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
    .header-user-button{
      width: 80px;
    }
    .header-logo {
      height: 40px;
      vertical-align: middle;
    }
    .header-menu-wrap {
      display: flex;
      align-items: center;

      >li{
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: relative;
        .second-menu{
          .second-menu-content{
            width: 800px;
            margin: auto;
            z-index: 999999;
          }

          display: none;
          padding: 20px;
          width: 100%;
          min-height: 100px;
          position: fixed;
          top:50px;
          left: 0;
          box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.1);
          background: white;
          :global(.ant-col){
            padding: 10px;
          }
          &.cases{

          }
          &.products{

          }
          &.solutions{

          }
        }
        &:hover{
          .second-menu{
            display: block;
          }
        }
      }

      .header-menu-item {
        padding: 0 15px;
        font-size: 16px;
        color: #333333;
        height: 100%;
        cursor: pointer;
      }
      .header-menu-item_check {
        padding: 0 15px;
        font-size: 16px;
        color: #14bd85;
        font-weight: bold;
        //position: relative;
        height: 100%;
        cursor: pointer;
        display: flex;
        .second-menu{
          color: initial;
          font-size: initial;
          font-weight: initial;
        }

        &::after {
          content: '';
          width: 100%;
          height: 2px;
          display: block;
          background-color: #14bd85;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: width 0.3s ease, background 0.3s ease;
        }
      }
    }
  }
}

:global{
  .root-menu{
    border-bottom: 0;
    background: transparent!important;
    .ant-menu-item{
      font-size: 16px;
      &.ant-menu-item-active{
        &::after{
          border-bottom-width: 3px!important;
          border-bottom-color: #14bd85!important;
        }
      }
      &.ant-menu-item-selected{
        color: #14bd85;
        font-weight: bold;
        &::after{
          border-bottom-width: 3px;
          border-bottom-color: #14bd85;
        }
      }
    }

  }

}

@primary-color: #14BD85;