@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.homepage-list-item_wrap {
  height: 156px;
  border: 1px solid #eceef4;
  border-radius: 5px;
  padding: 31px 19px 36px 21px;
  .homepage-list-item_title {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    flex-direction: column;
    align-items: center;
    display: inline-block;
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      border-radius: 20px;
      background-color: #14bd85;
      height: 2px;
      margin-top: 0px;
    }
  }
  .homepage-list-item_desc {
    color: #666666;
    font-size: 16px;
    line-height: 19px;
  }
}

@primary-color: #14BD85;