@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
// .swiper-slide {
//   position: relative;
//   transition: transform 0.3s, z-index 0.3s, opacity 0.3s;
// }
// .swiper-slide-active {
//   transform: scale(1.2) !important; /* 中间元素放大的比例 */
//   z-index: 1; /* 中间元素的层级 */
//   opacity: 1; /* 中间元素的透明度 */
// }
.swiper-release {
  background: linear-gradient(180deg, #e39c57 0%, #edb884 100%);
}
.swiper-source {
  background: linear-gradient(180deg, #1bae7d 0%, #68c7a7 92%);
}
.swiper-ome {
  background: linear-gradient(180deg, #5fa2f5 0%, #8bbaf5 100%);
}

@primary-color: #14BD85;