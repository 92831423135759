@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.detail{
  ul{
    li{
      margin: 20px auto;
    }
  }
}

.product-header{
  height: 190px;
  background: #eee;
  text-align: center;
  position: relative;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  .product-view-url{
    position: absolute;
    bottom: 65px;
    width: 100%;
    text-align: center;
    height: 0;
    transition: all 0.4s linear;
    overflow: hidden;
    a{
      color: rgb(7, 107, 255) !important;
      font-size: 16px !important;
      &:hover{
        color: rgb(53, 136, 255) !important;
      }
    }
  }
  .product-header-title{
    transform: translate(0);
    transition: all .3s linear;
    transition-delay: .1s;
    height: 120px;
    width: 100%;
    position: absolute;
    h3{
      margin-top: 10px;
      font-size:21px;
      font-weight: 400;
    }
  }
  .product-header-desc{
    bottom: 0;
    font-size: 13px;
    line-height: 24px;
    position: absolute;
    width: 100%;
    height: 55px;
    padding: 0 10px;
  }
  .product-header-icon{
    content: '';
    width: 56px;
    margin: auto;
    background-size: 100% auto;
    height: 80px;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  &.product1{
    background-image: url(@/assets/images/product/product-bg-01.png);
    background-size: cover;
    .product-header-icon{

    }
  }
  &.product2{
    background: url(@/assets/images/product/product-bg-02.png) center center;
    background-size: cover;
    .product-header-icon{
      background-image: url(@/assets/images/product/masonry02.png);
    }
    .product-header-title{
      h3{
        color: rgb(0, 84, 150);
      }
    }
  }
  &.product3{
    background: url(@/assets/images/product/product-bg-03.png) center center;
    background-size: cover;
    .product-header-icon{
      background-image: url(@/assets/images/product/masonry03.png);
    }
    .product-header-title{
      h3{
        color: rgb(0, 60, 146);
      }
    }
  }
  &.product4{
    background: url(@/assets/images/product/product-bg-04.png) center center;
    background-size: cover;
    .product-header-icon{
      background-image: url(@/assets/images/product/masonry04.png);
    }
    .product-header-title{
      h3{
        color: rgb(0, 32, 129);
      }
    }
  }
  &.product5{
    background: url(@/assets/images/product/product-bg-05.png) center center;
    background-size: cover;
    .product-header-icon{
      background-image: url(@/assets/images/product/masonry05.png);
    }
    .product-header-title{
      h3{
        color: rgb(0, 24, 118);
      }
    }
  }
}
.product-price{
  .contact-box, .free-box{
    margin-top: 10px;
  }
  .free-box{
    min-height: 50px;
    font-size: 20px;
    line-height: 50px;
  }
  .contact-box{
    min-height: 50px;
    font-size: 20px;
    line-height: 50px;
  }
  .link{
    margin-top: 10px;
  }
  text-align: center;
}

.p-item{
  display:flex;
  align-items: center;
  flex-direction: row;
  word-break: break-all;
  font-size: 14px;
  .p-item-icon{
    width: 24px;
    flex: none;
  }
}
@media only screen and (max-width: 768px) {
  .product-card {
    min-height: auto!important;
  }
}
.product-card{
  border: .5px solid rgba(23,26,29,.16)!important;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  min-height: 900px;

  :global{
    .ant-divider{
      margin: 10px auto;
    }
  }
  &:hover{
    border: .5px solid rgba(23, 26, 29, .24) !important;
    box-shadow: 0 4px 24px 0 rgba(0,0,0,.1);

    .product1,.product2,.product3,.product4,.product5{
      .product-view-url{
        height: 52px;
      }
      .product-header-title{
        //top: -53px;
        transform: translateY(-53px)!important;
      }
    }
    .product-header{
      &.product2{
        background-image: url(@/assets/images/product/product-bg-02-hover.png);
      }
      &.product3{
        background-image: url(@/assets/images/product/product-bg-03-hover.png);
      }
      &.product4{
        background-image: url(@/assets/images/product/product-bg-04-hover.png);
      }
      &.product5{
        background-image: url(@/assets/images/product/product-bg-05-hover.png);
      }
    }

  }
  :global{
    .ant-card-body{
      padding: 0;
    }
  }
  .detail{
    padding: 0 10px;
    ul{
      li{
        margin: 10px auto;
      }
    }
  }
}

.price-wrapper{
  font-size: 14px;
  line-height: 28px;
  .origin-price{
    .money{
      text-decoration-line: line-through;
    }
    .money-unit{

    }
  }
  .current-price{
    .money{
      color: #ff6700!important;
      font-size: 28px;
      font-weight: bold;
    }
    .money-unit{
      font-weight: bold;
    }
  }
  .deadline-wrapper{
    :global{
      .ant-tag{
        margin-inline-end: 0!important;
      }
    }
    .deadline-wrapper-tag{
      :global{
        .ant-statistic{
          display: inline-block;
        }
      }
    }
    *{
      font-size: 13px !important;
    }
  }
}

.online-contact-text{
  color: #ff6700!important;
  font-size: 18px;
  font-weight: bold;
}
.contactus-weixin-box{
  text-align: center;
  padding: 20px;
  h3{
    margin: 10px;
    font-width: bold;
    font-size: 16px;
  }
  img{
    width: 250px;
    height: 250px;
  }
}

@primary-color: #14BD85;