@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.thanks-wrap {
  width: 100%;
  padding: 100px 460px 142px 460px;
  background-color: white;
  .thanks-content-wrap {
    width: 100%;
    .thanks-content-title {
      font-size: 32px;
      color: #333333;
    }
    .thanks-content-desc {
      color: #333333;
      font-size: 16px;
      margin-top: 24px;
      margin-bottom: 21px;
    }
    .thanks-content-item {
      color: #14bd85;
      font-size: 16px;
      display: flex;
      align-items: center;
      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #333333;
        margin-right: 10px;
      }
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
  .vercode-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .vercode-content-wrap {
      padding: 42px 34px 33px 34px;
      background-color: #f6f6f7;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@primary-color: #14BD85;