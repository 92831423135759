@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
// 重置table组件样式
.remote-table {
  .ant-table-thead > tr > th {
    background: #f3f7ff;
    padding: 2px 16px 2px 8px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    // color: #f00;
  }
  .ant-table-thead > tr > th:first-child {
    background: #f3f7ff;
    padding-left: 16px !important;
  }
  .ant-table-tbody {
    height: calc(100% - 48px);
    > tr > td {
      padding: 12px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .ant-table-cell-content {
    width: 100%;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 12px !important;
  }
  // .ant-table-body {
  //   .ant-table-cell {
  //     padding: 12px 8px !important;
  //     color: rgba(0, 0, 0, 0.65);
  //   }
  // }
  .ant-table-cell::before {
    background-color: #dbe5f8 !important;
  }

  .ant-table-row-selected {
    background: #dde4fd;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    // background: #ecf0ff;
    background-color: #fafbff;
  }

  .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-arrow::before {
    background-color: #c4c4c4 !important;
  }

  // 修改表单样式
  .ant-form-item {
    margin-bottom: 16px;
  }

  // .ant-menu-item,
  // .ant-menu-submenu-title {
  //   color: #000000 !important;
  //   margin-top: 0px !important;
  // }

  // .ant-menu-item:hover,
  // .ant-menu-submenu-title:hover {
  //   background: #4982f5 !important;
  //   color: white !important;
  // }

  // .ant-menu {
  //   // background-color: transparent !important;
  // }

  // .ant-menu-title-content {
  //   margin-left: 16px !important;
  // }

  // .ant-menu-item-selected > .ant-menu-title-content:hover {
  //   color: white !important;
  // }

  // :global{

  .ant-checkbox-wrapper {
    margin: -3px !important;
  }

  tr.ant-table-measure-row {
    // display: none;
    visibility: collapse;
  }

  .ant-table-thead {
    height: 50px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
  }
  .ant-space.ant-space-horizontal.ant-space-align-center {
    // display: none;
  }
  .ant-table {
    height: calc(100%- 40px);
  }
  .ant-empty-normal {
    margin: 140px 0 !important;
  }
  .ant-table-wrapper {
    // height: calc(100% - 48px);
    min-height: 300px;
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .ant-table-empty {
          height: 100%;
        }
      }
    }
  }

  .ant-table-body {
    overflow: hidden;
    // overflow: auto !important;
    height: calc(100% - 54px);
  }
  .ant-table-container {
    height: 100%;
    .ant-table-content {
      height: 100%;
    }
  }

  .ant-table-summary {
    background-color: #f2f5fa;
  }
}

@primary-color: #14BD85;