@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.price-box{
  .original-price-text{
    color: gray;
    .original-price{
      text-decoration-line: line-through;

    }
  }
  .special-price{
    color: #ff6700!important;
    font-size: 18px;
  }
  .online-contact-text{
    color: #ff6700!important;
    font-size: 18px;
    font-weight: bold;
  }
}
:global{
  .product-table-column{
    font-size: 16px!important;
  }

  .page-price-type-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    .page-price-type-icon-dark, .page-price-type-icon-light, .page-price-type-icon-primary {
      display: inline-block;
      width: 12px;
      height: 20px;
      border-radius: 2px;
    }
    .page-price-type-icon-light:first-child {
      width: 12px;
      border-radius: 2px;
    }
    .page-price-type-icon-dark {
      background: #5cadff;
    }
    .page-price-type-icon-primary {
      background: #b37feb;
    }
    .page-price-type-icon-light {
      background: #dfecfe;
      margin-left: 2px;
      width: 6px;
      border-radius: 0 2px 2px 0;
    }
  }
}
.contactus-weixin-box{
  text-align: center;
  padding: 20px;
  h3{
    margin: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  img{
    width: 250px;
    height: 250px;
  }
}


.product-banner{
  background-image: url(@/assets/images/product-banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &-content{
    max-width: 1200px;
    margin: auto;
    height: 300px;
    padding: 20px;
    color: white;
    h1{
      margin-top: 80px;
    }
    &-ctrl{
      margin-top: 40px;
    }
  }
}

.product-banner-1{
  background-image: url(@/assets/images/product-banner-1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &-content{
    max-width: 1200px;
    margin: auto;
    height: 300px;
    padding: 20px;
    color: white;
    h1{
      margin-top: 80px;
    }
    &-ctrl{
      margin-top: 40px;
    }
  }
}
.second-title{
  text-align: center;
  margin: 40px;
  font-size: 28px;
  color: #333;
  position: relative;
  &::after{
    display: block;
    height: 3px;
    width: 40px;
    background: #14bd85;
    content: "";
    position: absolute;
    top: 45px;
    left: 50%;
    margin-left: -20px;
  }
}

.container-box{
  margin: 20px auto;
  max-width: 1200px;
  overflow-x: hidden;

  .container-msg{
    min-height: 300px;
    h3{
      margin: 20px auto 10px;
    }
  }
  .container-img{
    min-height: 300px;
    text-align: center;
    img{
      max-width: 600px;
    }
  }
}

@primary-color: #14BD85;