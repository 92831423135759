@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.personal-order-wrap {
  width: 100%;
  height: 100%;
  background-color: white;
  .personal-data-content_wrap {
    padding: 20px;
  }
}
.success-btn {
  color: #14bd85;
  font-size: 16px;
  cursor: pointer;
}
.warn-btn {
  color: #f56f7d;
  font-size: 16px;
  cursor: pointer;
}
.order-actions{
  *{
    font-size: 12px;
  }
  font-size: 12px;
  color: red;
}

@primary-color: #14BD85;