@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
//@import '~antd/es/style/themes/antd.less';

@import '@/common-style/index.less';
p,
div,
html,
body,
a,
ul,
li,
ol,
dl,
p,
h1,
h2,
h3,
h4,
h5,
h6,
main,
head,
span,
img {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  color: rgb(81, 90, 110);
}

/*修改滚动条样式*/
div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /**/
}

div::-webkit-scrollbar-track {
  // background: rgb(239, 239, 239);
  background: transparent;
  border-radius: 2px;
}

div::-webkit-scrollbar-thumb {
  // background: #bfbfbf;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}

div::-webkit-scrollbar-thumb:hover {
  // background: #333;
  background: rgba(0, 0, 0, 0.5);
}

div::-webkit-scrollbar-corner {
  // background: #179a16;
  background: transparent;
}

@primary-color: #14BD85;