@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.create-order-wrap {
  width: 100%;
  padding: 24px 11.4%;
  background-color: white;
  .create-order-process_wrap {
    width: 100%;
    height: 46px;
    border-radius: 4px;
    background-color: #f5f7fa;
    display: flex;
    margin-bottom: 28px;
    .create-order-process_item {
      padding-left: 94px;
      width: 33.3%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #6b8079;
    }
  }
  .create-order-content_wrap {
    width: 100%;
  }
}

@primary-color: #14BD85;