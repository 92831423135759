@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}

.second-title{
  text-align: center;
  margin: 40px;
  font-size: 28px;
  color: #333;
  position: relative;
  &::after{
    display: block;
    height: 3px;
    width: 40px;
    background: #14bd85;
    content: "";
    position: absolute;
    top: 45px;
    left: 50%;
    margin-left: -20px;
  }
}
.product-content-container{
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #edeff2;

}

.product-content-container-box{
  margin: 20px auto;
  max-width: 90%;

  :global{
    //.ant-col-xl-4, .ant-col-lg-4 {
    //  width: 20%;
    //  max-width: 20%;
    //  flex: 0 0 20%;
    //}
   /*
    */
    @media only screen and (max-width: 768px) {
      .ant-col-md-24 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .product-inner{
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    .product-item{
      :global{
        .ant-card-body{
          padding: 0;
        }
      }
      .upgrade-record-link{
        color: #e3e3e3;
        &:hover{
          color: white;
        }
      }
    }
  }
  .container-msg{
    min-height: 300px;
    h3{
      margin: 20px auto 10px;
    }
  }
  .container-img{
    min-height: 300px;
    text-align: center;
    img{
      max-width: 600px;
    }
  }
}
.product-desc{
  max-width: 1200px;
  margin: 20px auto auto;
  padding: 20px;
  p{
    margin: 10px auto;
  }
}

@primary-color: #14BD85;