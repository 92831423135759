@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.footer-wrap {
  width: 100%;
  background-color: #020c08;
  *{
    color: white;
  }
  .footer-content-wrap {
    max-width: 1200px;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 80px;
    border-bottom: 1px solid #262626;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .footer-item-title {
      font-size: 16px;
      color: #ffffff;
      font-weight: bold;
    }
    .footer-item-qrcode {
      font-size: 14px;
      color: #aaaaaa;
      margin-top: 12px;
    }
    .footer-img {
      width: 138px;
      height: 138px;
      background-color: gray;
      margin-top: 17px;
    }
    .footer-item {
      font-size: 14px;
      color: #959694;
      margin-top: 23px;
    }
  }
  .footer-copyright {
    width: 100%;
    min-height: 60px;
    line-height: 60px;
    text-align: center;
    color: #555555;
    font-size: 14px;
    white-space: nowrap;
  }
}

@primary-color: #14BD85;