@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.pay-code-container {
  padding: 50px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .pay-amount{
    font-weight: 600;
    font-family: PingFangSC-Regular;
    font-size: 22px;
    color: #f7603e;
    vertical-align: 1px;
    margin-top: 10px;
    strong{
      color: #f7603e;
    }
  }
  .pay-last-time{
    margin: 5px;
  }
  .pay-last-time::before{
    background: url('../../../assets/images/timer.png') no-repeat 50% 50%;
    content: "";
    margin-right: 8px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    vertical-align: -3px;
  }
  .pay-safe{
    margin-top: 10px;
  }
  .pay-safe::before{
    background: url('../../../assets/images/safe.png') no-repeat 50% 50%;
    content: "";
    margin-right: 8px;
    display: inline-block;
    width: 14px;
    height: 16px;
    background-size: 14px 16px;
    vertical-align: -3px;
  }
  .pay-code-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .pay-title {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

@primary-color: #14BD85;