@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.custom-layout {
  :global {
    .ant-row,
    .ant-form-item {
      display: inline-block;
      width: 23%;
      margin-right: 2%;
      .ant-form-item-label,
      .ant-form-item-control {
        width: 100%;
      }
    }
  }
}

@primary-color: #14BD85;