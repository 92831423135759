@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}

.product-update-record-banner{
  background-image: url(@/assets/images/product-update-record-banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &-content{
    width: 1200px;
    margin: auto;
    height: 300px;
    padding: 20px;
    color: white;
    h1{
      margin-top: 80px;
    }
    &-ctrl{
      margin-top: 40px;
    }
  }
}

.product-desc{
  margin-top: 20px;
  p{
    margin: 10px auto;
  }
}

.second-title{
  text-align: center;
  margin: 40px;
  font-size: 28px;
  color: #333;
  position: relative;
  &::after{
    display: block;
    height: 3px;
    width: 40px;
    background: #14bd85;
    content: "";
    position: absolute;
    top: 45px;
    left: 50%;
    margin-left: -20px;
  }
}

.container-box{
  margin: 20px auto;
  max-width: 1200px;

  .container-msg{
    min-height: 300px;
    h3{
      margin: 20px auto 10px;
    }
  }
  .container-img{
    min-height: 300px;
    text-align: center;
    img{

      max-width: 600px;
    }
  }
}

.record-list{
  min-height: 300px;
  .record-item{
    padding: 20px;
    min-height: 50px;
    margin-top: 20px;
    border-radius: 8px;
    &:hover{
      background: #f6f6f6;

    }
    .record-title{
      font-size: 24px;
      margin-bottom: 10px;
      font-width: bold;
    }
    .record-date{
      margin-bottom: 10px;
      :global{
        .ant-tag{
          font-size: 14px;
          font-weight: bold;
          color: #409eff;
        }
      }
    }
    .record-desc{

    }
  }
}

@primary-color: #14BD85;