@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.pay-wrap {
  width: 100%;
  padding: 20px 18.75%;
  background-color: #eef1f0;
  .pay-content-wrap {
    width: 100%;
    background-color: white;
    .pay-content-title_wrap {
      width: 100%;
      border-bottom: 1px solid #d9d9d9;
      height: 49px;
      line-height: 49px;
      color: #2f312e;
      padding-left: 14px;
      font-size: 16px;
    }
    .pay-content {
      padding: 29px 29px 300px 29px;
      .pay-content-item {
        color: #2f312e;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
}

@primary-color: #14BD85;