@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.home-banner{
  .banner1{
    width: 1200px;
    margin: auto;
    height: 540px;
    .banner1-content{
      padding-top: 50px;
      height: 400px;
      width: 500px;
    }
  }
  .banner2{
    width: 1200px;
    margin: auto;
    height: 540px;
    .banner1-content{
      padding-top: 50px;
      height: 400px;
      width: 500px;
    }
    .homepage-banner-btn_wrap {
      display: flex;
      text-align: center;
      width: 100%;
      justify-content:center;
      align-items: center;
      margin-top: 52px;
      .homepage-banner-btn_preview {
        width: 94px;
        height: 42px;
        line-height: 42px;
        border-radius: 30px;
        text-align: center;
        background-color: #14bd85;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .banner3{
    width: 1200px;
    margin: auto;
    height: 540px;
    .banner1-content{
      padding-top: 50px;
      height: 400px;
      width: 500px;
    }
    .homepage-banner-btn_wrap {
      display: flex;
      text-align: center;
      width: 100%;
      justify-content:center;
      align-items: center;
      margin-top: 52px;
      .homepage-banner-btn_preview {
        width: 94px;
        height: 42px;
        line-height: 42px;
        border-radius: 30px;
        text-align: center;
        background-color: #14bd85;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
      .homepage-banner-btn_start {
        width: 108px;
        height: 42px;
        line-height: 42px;
        border-radius: 30px;
        text-align: center;
        background-color: #ffffff;
        font-size: 14px;
        color: #606266;
        border: 1px solid #dddfe5;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .banner4{
    width: 1200px;
    margin: auto;
    height: 540px;
    .banner1-content{
      padding-top: 50px;
      height: 400px;
      width: 500px;
    }
    .homepage-banner-btn_wrap {
      display: flex;
      text-align: center;
      width: 100%;
      justify-content:center;
      align-items: center;
      margin-top: 52px;
      .homepage-banner-btn_preview {
        width: 94px;
        height: 42px;
        line-height: 42px;
        border-radius: 30px;
        text-align: center;
        background-color: #14bd85;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
      .homepage-banner-btn_start {
        width: 108px;
        height: 42px;
        line-height: 42px;
        border-radius: 30px;
        text-align: center;
        background-color: #ffffff;
        font-size: 14px;
        color: #606266;
        border: 1px solid #dddfe5;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  :global{
    .slick-dots{
      li{
        width: 50px;
        button{
          border: 1px solid #afe3d2;
          background: #afe3d2;
          width: 50px;
          height: 10px;
        }
        &.slick-active{
          width: 80px;
          button{
            width: 80px;
            background: #14bd85;
          }
        }
      }
    }
  }
}
.homepage-banner-description_title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #333333;
  font-weight: bold;
}
.homepage-banner-description {
  margin-top: 39px;
  color: #585e59;
  font-size: 20px;
}
.homepage-wrap{
  background: url('../../../../assets/images/banner@3x.png') no-repeat center;
  background-size: cover;
  .homepage-banner-wrap {
    max-width: 1200px;
    height: 540px;

    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .homepage-banner-description_wrap {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 40px;
      .homepage-banner-description_title {
        width: 100%;
        text-align: center;
        font-size: 30px;
        color: #333333;
        font-weight: bold;
      }
      .homepage-banner-description {
        margin-top: 39px;
        color: #585e59;
        font-size: 20px;
      }
      .homepage-banner-btn_wrap {
        display: flex;
        text-align: center;
        width: 100%;
        justify-content:center;
        align-items: center;
        margin-top: 52px;
        .homepage-banner-btn_preview {
          width: 94px;
          height: 42px;
          line-height: 42px;
          border-radius: 30px;
          text-align: center;
          background-color: #14bd85;
          font-size: 14px;
          color: #ffffff;
          cursor: pointer;
        }
        .homepage-banner-btn_version {
          width: 94px;
          height: 42px;
          line-height: 42px;
          border-radius: 30px;
          text-align: center;
          background-color: #fcf0f0;
          font-size: 14px;
          color: #e47470;
          border: 1px solid #f2c6c5;
          margin-left: 12px;
          cursor: pointer;
        }
        .homepage-banner-btn_start {
          width: 108px;
          height: 42px;
          line-height: 42px;
          border-radius: 30px;
          text-align: center;
          background-color: #ffffff;
          font-size: 14px;
          color: #606266;
          border: 1px solid #dddfe5;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
    .homepage-banner-image {
      max-width: 650px;
      margin-left: 50px;
    }
  }
}

.homepage-list-wrap {
  margin: 50px auto 50px;
  width: 1200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .homepage-list-title {
    color: #606266;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 550px;
      height: 1px;
      background-color: #d9d9d9;
      position: absolute;
      left: -576px;
      top: 50%;
    }
    &::after {
      content: '';
      display: block;
      width: 550px;
      height: 1px;
      background-color: #d9d9d9;
      position: absolute;
      right: -576px;
      top: 50%;
    }
  }
}

@primary-color: #14BD85;