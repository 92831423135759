@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
//修改后不抖动
.ant-tabs > .ant-tabs-nav {
  font-family: PingFang SC-Regular;
  font-size: 14px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  // border-right: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: none;
  border-right: 1px solid 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid 1px solid rgba(0, 0, 0, 0.1);
}
.ant-tabs-tab {
  padding: 8px 16px 8px 14px;
  margin: 0 !important;
  background: transparent;
  border: none;
  font-size: 14px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.65);
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  // border-top: 2px solid @paramry-color;
  background: #ffff;
  // border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: @primary-color;
  height: 2px;
  width: 100%;
}
.ant-tabs-ink-bar {
  height: 0;
}
.tab-span-count {
  // padding-left: 8px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}
.ant-tabs-tab-active {
  .tab-span-count {
    color: #4982f5;
  }
}

// // tab样式
// .ant-tabs > .ant-tabs-nav {
//   font-family: PingFang SC-Regular;
//   font-size: 14px;
// }
// .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
//   border-radius: 0 !important;
// }
// .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
//   // border-right: 1px solid rgba(0, 0, 0, 0.1);
//   background: #fff;
// }
// .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
//   border: none;
//   border-right: 1px solid 1px solid rgba(0, 0, 0, 0.1);
//   border-bottom: 1px solid 1px solid rgba(0, 0, 0, 0.1);
// }
// .ant-tabs-tab {
//   padding: 8px 16px;
//   background: transparent;
//   border: none;
//   font-size: 14px;
//   margin-bottom: 0;
//   color: rgba(0, 0, 0, 0.65);
// }
// .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
//   border-top: 2px solid @paramry-color;
//   background: #ffff;
//   // border-right: 1px solid rgba(0, 0, 0, 0.1);
// }
// .ant-tabs-ink-bar {
//   height: 0;
// }
// .tab-span-count {
//   // padding-left: 8px;
//   color: rgba(0, 0, 0, 0.4);
//   font-size: 12px;
// }
// .ant-tabs-tab-active {
//   .tab-span-count {
//     color: #4982f5;
//   }
// }

@primary-color: #14BD85;