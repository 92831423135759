@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.commercial-license-wrap {
  width: 1200px;
  padding: 42px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .commercial-license-title {
    color: #2f312e;
    font-size: 30px;
  }
  .commercial-license-desc {
    color: #919499;
    font-size: 16px;
    margin-top: 21px;
    margin-bottom: 73px;
  }
  .commercial-license-swiper_wrap {
    width: 100%;
  }
  .commercial-license-authorization {
    width: 100%;
    padding: 11px 0px 11px 16px;
    position: relative;
    margin-top: 41px;
    font-size: 14px;
    color: #5c6674;
    &::before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #14bd85;
    }
  }
  .commercial-license-problem_wrap {
    margin-top: 32px;
    .commercial-license-problem_title {
      color: #2f312e;
      font-size: 22px;
    }
    .commercial-license-problem_subtitle {
      color: #2f312e;
      font-size: 16px;
      margin-top: 16px;
    }
    .commercial-license-problem_desc {
      color: #838896;
      margin-top: 12px;
      font-size: 14px;
    }
  }
  .commercial-license-concat {
    width: 100%;
    border-radius: 4px;
    height: 37px;
    margin-top: 20px;
    background-color: #f4f4f5;
    padding-left: 15px;
    display: flex;
    align-items: center;
    color: #2f312e;
    font-size: 14px;
  }
}

@primary-color: #14BD85;