@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.float-btn-grp{
  :global{
    .ant-float-btn-body{
      height: auto!important;
    }
    .ant-tooltip-inner{
      background-color: white!important;
      color: #333!important;
    }
  }
}
.contact-us{
  width: 300px;
  min-height: 50px;
  h3{
    font-size: 16px;
    font-weight: 600;
  }
  .phone{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #14bd85;
  }
}

@primary-color: #14BD85;