@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}

.solution-banner{
  background-image: url(@/assets/images/solution-banner.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &-content{
    width: 1200px;
    margin: auto;
    height: 300px;
    padding: 20px;
    color: white;
    h1{
      margin-top: 80px;
    }
    &-ctrl{
      margin-top: 40px;
    }
  }
}
.container-box{
  margin: auto auto 20px;
  max-width: 1200px;
}

.classics-item{
  cursor: pointer;
  color: white;
  padding: 100px 40px 40px;
  text-align: center;
  background: linear-gradient(to top, #73c2a8, #14bd85);
  background: -ms-linear-gradient(to top, #73c2a8, #14bd85);
  background: -webkit-linear-gradient(to top, #73c2a8, #14bd85);
  background: -moz-linear-gradient(to top, #73c2a8, #14bd85);

  .classics-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    &::before{
      width: 49px;
      height: 50px;
      content: "";
      position: absolute;
      top: 35px;
      left: 50%;
      margin-left: -24px;
    }
    &::after{
      width: 30px;
      height: 2px;
      background: #fff;
      content: "";
      position: absolute;
      top: 135px;
      left: 50%;
      margin-left: -15px;
    }
  }
  .classics-icon-1{
    &::before{
      background: url(../../assets/images/solution/classics-icon-1.png) 0 0 no-repeat;
    }
  }
  .classics-icon-2{
    &::before{
      background: url(../../assets/images/solution/classics-icon-2.png) 0 0 no-repeat;
    }
  }
  .classics-icon-3{
    &::before{
      background: url(../../assets/images/solution/classics-icon-3.png) 0 0 no-repeat;
    }
  }
  .classics-icon-4{
    &::before{
      background: url(../../assets/images/solution/classics-icon-4.png) 0 0 no-repeat;
    }
  }
  .classics-icon-5{
    &::before{
      background: url(../../assets/images/solution/classics-icon-5.png) 0 0 no-repeat;
    }
  }
  .classics-icon-6{
    &::before{
      background: url(../../assets/images/solution/classics-icon-6.png) 0 0 no-repeat;
    }
  }
}


.second-title{
  text-align: center;
  margin: 40px;
  font-size: 28px;
  color: #333;
  position: relative;
  &::after{
    display: block;
    height: 3px;
    width: 40px;
    background: #14bd85;
    content: "";
    position: absolute;
    top: 45px;
    left: 50%;
    margin-left: -20px;
  }
}


.solution-item{
  text-align: center;
  max-width: 1200px;
  margin: auto;

  h4{
    font-size: 20px;
    font-weight: 300;
  }
  .detail{
    margin: 20px;
    color: #666;
    text-align: justify-all;
  }
}
.solution-portal-img1, .solution-portal-img2, .solution-portal-img3, .solution-portal-img4{
  height: 150px;
  background-size: cover;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.solution-portal-item{
  max-width: 1200px;
  margin: auto;
  margin-bottom: 20px;
  .solution-portal-img1{
    background-image: url("../../assets/images/solution-portal-1.png");
  }
  .solution-portal-img2{
    background-image: url("../../assets/images/solution-portal-2.png");
  }
  .solution-portal-img3{
    background-image: url("../../assets/images/solution-portal-3.png");
  }
  .solution-portal-img4{
    background-image: url("../../assets/images/solution-portal-4.png");
  }
  .detail{
    margin: auto;
    color: #666;
    text-align: justify-all;
  }
}
.concat-us{
  background: #14bd85;
  max-width: 1200px;
  margin: auto auto 20px;
  text-align: center;
  color: white;
  padding: 40px;
  line-height: 50px;
  .second-title{
    color: white;
    margin: 0;
  }
}


.homepage-wrap{
  background: url('../../assets/images/banner@3x.png') no-repeat center;
  background-size: cover;
  .homepage-banner-wrap {
    width: 1200px;
    height: 540px;

    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .homepage-banner-description_wrap {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 40px;
      .homepage-banner-description_title {
        width: 100%;
        text-align: center;
        font-size: 30px;
        color: #333333;
        font-weight: bold;
      }
      .homepage-banner-description {
        margin-top: 39px;
        color: #585e59;
        font-size: 20px;
      }
      .homepage-banner-btn_wrap {
        display: flex;
        text-align: center;
        width: 100%;
        justify-content:center;
        align-items: center;
        margin-top: 52px;
        .homepage-banner-btn_preview {
          width: 94px;
          height: 42px;
          line-height: 42px;
          border-radius: 30px;
          text-align: center;
          background-color: #14bd85;
          font-size: 14px;
          color: #ffffff;
          cursor: pointer;
        }
        .homepage-banner-btn_version {
          width: 94px;
          height: 42px;
          line-height: 42px;
          border-radius: 30px;
          text-align: center;
          background-color: #fcf0f0;
          font-size: 14px;
          color: #e47470;
          border: 1px solid #f2c6c5;
          margin-left: 12px;
          cursor: pointer;
        }
        .homepage-banner-btn_start {
          width: 108px;
          height: 42px;
          line-height: 42px;
          border-radius: 30px;
          text-align: center;
          background-color: #ffffff;
          font-size: 14px;
          color: #606266;
          border: 1px solid #dddfe5;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
    .homepage-banner-image {
      width: 650px;
      margin-left: 50px;
    }
  }
}

.homepage-list-wrap {
  margin: 50px auto 50px;
  width: 1200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .homepage-list-title {
    color: #606266;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 550px;
      height: 1px;
      background-color: #d9d9d9;
      position: absolute;
      left: -576px;
      top: 50%;
    }
    &::after {
      content: '';
      display: block;
      width: 550px;
      height: 1px;
      background-color: #d9d9d9;
      position: absolute;
      right: -576px;
      top: 50%;
    }
  }
}

@primary-color: #14BD85;