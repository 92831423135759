@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.upload {
  position: relative;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px dashed rgba(0, 0, 0, 0.1);
}
.upload,
.upload-items,
.upload-item {
  display: inline-block;
  vertical-align: top;
}
.upload-plus-wrap {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .upload-plus-icon {
    width: 33px;
    height: 33px;
  }
}
.upload-items {
  width: auto;
  height: auto;
  .upload-item {
    position: relative;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 16px;
    z-index: 1;
    .upload-item-img-wrap {
      border-radius: 2px 2px 2px 2px;
      overflow: hidden;
      .upload-item-img {
        width: 100%;
        height: 100%;
      }
    }

    .main-graph {
      position: absolute;
      width: 100%;
      height: 20px;
      background: #52d46f;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
      text-align: center;
      bottom: 0;
    }
    .upload-item-delete {
      position: absolute;
      width: 30px;
      height: 30px;
      right: -12px;
      top: -12px;
      z-index: 3;
    }
  }
}
.upload-item:hover .upload-item-mask {
  display: block;
}
.upload-item-mask {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: bold;
  color: #fff;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2 linear;
  .item-mask-text {
    position: absolute;
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.upload-row {
  width: 120px;
  height: 120px;
}
.upload-single {
  width: 80px;
  height: 80px;
  margin-left: 0 !important;
}
.upload-row-img {
  width: 118px;
  height: 118px;
}
.upload-single-img {
  width: 78px;
  height: 78px;
}

@primary-color: #14BD85;