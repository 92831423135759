@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}
.no-data-wrap {
  width: 64px;
  height: auto;
  margin: 57px auto;
  .no-data-icon {
    width: 64px;
    height: 64px;
  }
  .no-data-text {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.45);
    line-height: 16px;
    text-align: center;
    margin-top: 10px;
  }
}
//内容过多以...显示
.ellipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
//显示拖拽样式
// .components-table-resizable-column {
//   height: 100%;
// }
// .components-table-resizable-column .react-resizable {
//   position: relative;
//   background-clip: padding-box;
// }
// .components-table-resizable-column
.require-title {
  .require-star {
    font-size: 14px;
    font-weight: bold;
    color: #ff4d4f;
    margin-right: 4px;
    vertical-align: middle;
  }
}
.table-wrap {
  position: relative;
  height: 100%;
  :global {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
      height: 53px;
      top: 50%;
      background-color: #fff !important;
    }
    .ant-table-thead > tr > th {
      position: relative;
    }
    .ant-table-wrapper {
      height: 100%;
      .ant-spin-nested-loading {
        .ant-spin-container {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: 0px;
    cursor: col-resize;
    z-index: 1;
    height: 53px;
    background-color: transparent;
  }
  .drag-line {
    position: absolute;
    left: 0;
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: #4982f5;
    z-index: 9999;
    display: none;
  }
  .start-line {
    position: absolute;
    left: 0;
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: #4982f5;
    // background-color: red;
    z-index: 9999;
    display: none;
  }
}

@primary-color: #14BD85;