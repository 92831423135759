@primary-color: #14bd85;
@btn-primary-color: #14bd85;
@images-url: '@assets/images/';
.flex {
  display: flex;
}
.flex-row-center {
  &:extend(.flex);
  justify-content: center;
  align-items: center;
}
.flex-row-space-between {
  &:extend(.flex);
  justify-content: space-between;
}
.flex-column-center {
  flex-direction: column;
  &:extend(.flex-row-center);
}
.flex-column-sapce-between:extend(.flex-row-space-between) {
  flex-direction: column;
  align-items: center;
}

.href {
  color: @primary-color;
}
.href:hover {
  text-decoration: underline;
}

.about-us-banner{
  background-image: url(../../assets/images/about-us-banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &-content{
    width: 1200px;
    margin: auto;
    height: 300px;
    padding: 20px;
    color: white;
    h1{
      margin-top: 80px;
    }
    &-ctrl{
      margin-top: 40px;
    }
  }
}

.container-box{
  margin: auto auto 20px;
  max-width: 1200px;

}


.second-title{
  text-align: center;
  margin: 40px;
  font-size: 28px;
  color: #333;
  position: relative;
  &::after{
    display: block;
    height: 3px;
    width: 40px;
    background: #14bd85;
    content: "";
    position: absolute;
    top: 45px;
    left: 50%;
    margin-left: -20px;
  }
}

.company-desc{
  p{
    margin: 20px;
    text-indent: 40px;
  }
}
.company-slogan{
  text-align:center;
  h4{
    margin: 20px 0 0;
  }
}

.contact-wrap {
  width: 800px;
  height: 100%;
  background-color: white;
  margin: auto;
  .contact-content-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    .contact-content-title {
      color: #2f312e;
      font-size: 30px;
    }
    .contact-content-desc {
      font-size: 16px;
      color: #919499;
      margin-top: 21px;
      margin-bottom: 40px;
    }
    .code-wrap {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: auto;
      column-gap: 22px;
      .code-item-wrap {
        box-shadow: 0px 0px 12px 2px rgba(16, 19, 18, 0.08);
        border-radius: 5px;
        .code-item-title_wrap {
          width: 100%;
          text-align: center;
          height: 40px;
          line-height: 40px;
          color: #2f312e;
          font-size: 14px;
          border-bottom: #eceef4;
        }
        .code-item-content_wrap {
          padding: 17px 31px 31px 31px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          .code-item-content_desc {
            color: #666666;
            font-size: 12px;
          }
          .code-item-image {
            width: 238px;
            height: 250px;
            margin-top: 14px;
          }
        }
      }
    }
    .separation {
      color: #666666;
      font-size: 16px;
      font-weight: bold;
      position: relative;
      margin-top: 60px;
      &::before {
        content: '';
        display: block;
        width: 370px;
        height: 1px;
        background-color: #eeeeee;
        position: absolute;
        top: 50%;
        left: -389px;
      }
      &::after {
        content: '';
        display: block;
        width: 370px;
        height: 1px;
        background-color: #eeeeee;
        position: absolute;
        top: 50%;
        right: -389px;
      }
    }
    .contact-submit {
      background-color: #14bd85;
      width: 100px;
      height: 40px;
      color: white;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

@primary-color: #14BD85;